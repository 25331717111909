<template>
  <article
    class="user-card-company user-card"
    href="#"
  >
    <div class="user-card__first">
      <div class="user-card__group">
        <ui-icon
          class="user-card__icon"
          glyph="briefcase"
        />

        <div class="user-card__label">{{ company.name }} {{ company.lastname }}</div>
      </div>
    </div>

    <div class="user-card__second">
      <actions-button
        :appearance="$pepper.Appearance.PRIMARY"
        @click="onChange"
        :size="$pepper.Size.S"
      >{{ $t('resto.company_change') }}</actions-button>
    </div>
  </article>
</template>

<script>
export default {
  name: 'FormsCompany',

  inject: [
    '$localStorage'
  ],

  data: () => {
    return {
      company: null,
    }
  },

  computed: {
    name() {
      return
    }
  },

  methods: {
    onChange() {
      this.$localStorage.company = null
      this.$router.push({ name: 'sayl-front-catalog.b2b' }).catch(() => {})
    }
  },

  created() {
    this.company = this.$localStorage.company
  }
}
</script>
