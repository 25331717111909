<template>
  <main>
    <div class="container">
      <header class="user__header">
        <navigations-breadcrumb :children="breadcrumbs" />
        <h1 class="user__title">{{ $t('resto.user_company_title') }}</h1>
      </header>

      <div class="user__body">
        <card-company />
      </div>
    </div>
  </main>
</template>

<script>
import NavigationsBreadcrumb from '@/components/navigations/breadcrumb'
import CardCompany from '../../components/ui/card-company.vue'

export default {
  name: 'UserCompany',

  components: {
    NavigationsBreadcrumb,
    CardCompany
  },

  data() {
    return {
      breadcrumbs: [
        { label: this.$t('resto.user_account_title'), href: {name: 'sayl-front-user.home'} }, //Your account',
        { label: this.$t('resto.user_company_title'), href: '#' }
      ],
    }
  }
}
</script>
